$(document).ready(function () {

AOS.init();

$('#nav-icon').click(function(){
  $(this).toggleClass('open');
  $('.navbarNav').toggleClass('open');
});
  $(".slider").slick({
    autoplay: true,
    arrows: false,
    dots: true,
    customPaging : function(slider, i) {
    var thumb = $(slider.$slides[i]).data();
    return '<a class="dot">'+(i+1)+'</a>';
            },
    responsive: [{ 
        breakpoint: 768,
        settings: {
            slidesToShow: 1,
            dots: false,
        } 
    }]
});

const options = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
    adaptiveHeight: true,
    autoplay: false,
    prevArrow: "<img class='a-left control-c prev slick-prev' src='./../images/left-arrow.png'>",
    nextArrow: "<img class='a-right control-c next slick-next' src='./../images/right-arrow.png'>",
    responsive: [
      {
        breakpoint: 992,
        settings: {
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          dots: false,
        }
      },
    ]
  };
  
  // my slick slider as constant object
  const mySlider = $('.testimonial').on('init', function(slick) {
  
    // constant var
    const slider = this;
      
    // slight delay so init completes render
    setTimeout(function() {
  
      // dot buttons
      let dots = $('.slick-dots > LI > BUTTON', slider);
  
      // each dot button function
      $.each(dots, function(i,e) {
  
        // slide id
        let slide_id = $(this).attr('aria-controls');
        
        // custom dot image
        let dot_img = $('#'+slide_id).data('dot-img');
        
        $(this).html('<img src="' + dot_img + '" alt="" />');
  
      });
  
    }, 100);
  
  
  }).slick(options);
  
  $('.screenshots').slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  });


});

